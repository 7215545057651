import { API_DEPLOYMENT_V3, DB_LOGS } from '../core/CcxEnv';
import DbLogs from '../types/DbLogs';
import ApiService from './ApiService';
import { DbLogsEntries } from '../types/DbLogsEnteries';

export default class DataStoreLogsService extends ApiService {
    /**
     * Get list of available database privileges.
     * @param {string} dataStoreUuid
     * @param dateRange
     */
    static async getDataStoreLogs(dataStoreUuid: string): Promise<DbLogs> {
        const response = await this.request(
            'GET',
            `data-stores/${dataStoreUuid}/${DB_LOGS}`
        );
        return new DbLogs(response);
    }

    static async getDataStoreLogsEntries(
        dataStoreUuid: string,
        fileName: string
    ): Promise<DbLogsEntries> {
        const response = await this.request(
            'GET',
            `data-stores/${dataStoreUuid}/${DB_LOGS}/entries?filename=${fileName}`
        );
        return new DbLogsEntries(response);
    }

    static async downloadDataStoreLogs(
        dataStoreUuid: string,
        fileName: string
    ): Promise<any> {
        const response = await fetch(
            `${API_DEPLOYMENT_V3}/data-stores/${dataStoreUuid}/${DB_LOGS}/download?filename=${fileName}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/x-gzip',
                },
            }
        );
        return response;
    }

    protected static getApiUrl() {
        return API_DEPLOYMENT_V3;
    }
}
