import AppConfirmDialog from '../../../AppConfirmDialog';
import ProvisionService from '../../../../services/ProvisionService';
import Service from '../../../../types/Service';
import { ReloadOutlined } from '@ant-design/icons';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import { notification } from 'antd';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';

export type RebootNodeButtonProps = {
    service: Service;
};

function RebootNodeButton({ service }: RebootNodeButtonProps) {
    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: service.getClusterUuid(),
    });

    const handleOk = async () => {
        try {
            await ProvisionService.rebootNode(
                service.getClusterUuid(),
                service.getServiceUuid()
            );
            refreshJobs();
            notification.open({
                message: 'Restart Node',
                description: `Restarting node (${service.getHostnameOrIP()}) in progress; it will be ready soon.`,
                icon: <CcxIconInfoCircleTwoTone />,
            });
        } catch (e: any) {
            notification.open({
                message: 'Restart Node',
                description: `Failed to restart node (${service.getHostnameOrIP()}). Please try again.`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };
    return (
        <AppConfirmDialog
            label="Reboot"
            stretchedClick={true}
            onOk={handleOk}
            title="WARNING!"
            content="During this operation, all processes will be temporarily paused as the node undergoes a reboot. Once the reboot is complete, both the datastore will be ready to handle requests again."
            actionIcon={<ReloadOutlined />}
        />
    );
}

export default RebootNodeButton;
