import { ReactElement, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Dropdown,
    Menu,
    Modal,
    notification,
    Pagination,
    Row,
    Spin,
    Tooltip,
} from 'antd';
import CcxComponentProps from '../../../core/CcxComponent';
import AppEmpty from '../../AppEmpty';
import AppGridTable from '../../ccx/common/AppGridTable';
import styles from './DataStores.module.less';
import { Link, useHistory, useParams } from 'react-router-dom';
import DeploymentsItem from '../../../types/DeploymentsItem';
import ProvisionService from '../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../ccx/icons/CcxIconCloseCircleTwoTone';
import CcxContextualMenuIcon from '../../ccx/icons/CcxContextualMenuIcon';
import DataStoreInformation from './DataStoreInformation';
import DataStoreStatus from './DataStoreStatus';
import CloudInformation from './CloudInformation';
import DatabaseInformation from './DatabaseInformation';
import DataStoreChart from './DataStoreChart';
import NoChartAvailable from '../../ccx/common/NoChartAvailable';
import CcxIconInfoCircleOutlined from '../../ccx/icons/CcxIconInfoCircleOutlined';
import AppDeleteModal from '../../AppDeleteModal';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { updateProfile } from '../../../slices/user.slice';
import {
    getAllDataStores,
    GetAllDataStoresParams,
} from '../../../slices/datastores.slice';
import { DeleteOutlined, FileTextOutlined } from '@ant-design/icons';
import { SelectedFilterOptionsInterface } from '../Projects';
import LazyLoader from '../../LazyLoader';

interface UrlProps {
    projectUuid: string;
}

export type PaginationProps = {
    perPage: number;
    page: number;
};

interface Props extends CcxComponentProps {
    selectedFilters: SelectedFilterOptionsInterface;
    filterCount: number;
}

function DataStores({
    testId = 'DataStores',
    selectedFilters,
    filterCount,
}: Readonly<Props>): ReactElement {
    const history = useHistory();
    const { projectUuid } = useParams<UrlProps>();

    const { datastores, totalItems } = useAppSelector(
        (state) => state.datastores
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination, setPagination] = useState<PaginationProps>({
        perPage: 10,
        page: 1,
    });
    const [rows, setRows] = useState<any>([]);
    const [updating, setUpdating] = useState<boolean>(false);
    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );
    const confirmPhrase = 'delete';

    const { user } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();

    const onAlertClose = async () => {
        try {
            dispatch(updateProfile());
        } catch (e) {
            notification.open({
                message: 'New User',
                description: `There was an error . ${e}`,
                icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
            });
        }
    };

    const QPSTitle = (
        <Tooltip
            placement="bottom"
            title={'Showing last 15 minutes of activity'}
        >
            Queries per second <CcxIconInfoCircleOutlined />
        </Tooltip>
    );

    const columns = [
        { title: 'Datastore', span: 5 },
        { title: 'Status', span: 4 },
        { title: 'Database', span: 4 },
        { title: 'Cloud provider', span: 4 },
        { title: QPSTitle, span: 5 },
    ];

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            setUpdating(true);
            dispatch(getAllDataStores(processDataToDispatch()));
            setUpdating(false);
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, [selectedFilters, pagination]);

    const processDataToDispatch = (
        paginationData?: PaginationProps
    ): GetAllDataStoresParams => {
        if (!paginationData) {
            paginationData = { ...pagination };
        }
        return {
            page: paginationData.page,
            per_page: paginationData.perPage,
            tags: selectedFilters.tags,
            db_vendors: selectedFilters.dbVendors,
        };
    };

    useEffect(() => {
        if (datastores) {
            if (user?.isNewUser() && datastores.deployments.length > 0) {
                onAlertClose();
            }

            setRows(
                datastores?.map((d: DeploymentsItem) => {
                    function handleMenuOptionClick(e: any) {
                        if (e.key === 'details') {
                            history.push(
                                `/projects/${projectUuid}/data-stores/${d.dataStoreUuid}/overview`
                            );
                        }
                    }

                    const onConfirmDelete = () => {
                        return new Promise(async (resolve, reject) => {
                            if (d) {
                                Modal.destroyAll();

                                try {
                                    await ProvisionService.removeDeployment(
                                        d.dataStoreUuid
                                    );

                                    dispatch(
                                        getAllDataStores(
                                            processDataToDispatch()
                                        )
                                    );

                                    notification.open({
                                        message: 'Delete datastore',
                                        description:
                                            'The datastore will be removed soon.',
                                        icon: <CcxIconInfoCircleTwoTone />,
                                    });

                                    resolve(null);
                                } catch (e) {
                                    notification.open({
                                        message: 'Delete datastore',
                                        description: `There was an error trying to delete the datastore. ${e}`,
                                        icon: (
                                            <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />
                                        ),
                                    });

                                    console.error(e);
                                    reject();
                                }
                            }
                        }).catch(() => console.log('Oops errors!'));
                    };

                    const menu = (
                        <Menu onClick={handleMenuOptionClick}>
                            {!d.isUnreachable() && (
                                <Menu.Item
                                    key="details"
                                    icon={<FileTextOutlined />}
                                >
                                    Details
                                </Menu.Item>
                            )}

                            <Menu.Divider />

                            <Menu.Item
                                key="delete"
                                danger={true}
                                icon={<DeleteOutlined />}
                            >
                                <AppDeleteModal
                                    stretchedClick={true}
                                    confirmPhrase={confirmPhrase}
                                    onConfirmDelete={onConfirmDelete}
                                    datastore={d}
                                    toDelete="datastore"
                                />
                            </Menu.Item>
                        </Menu>
                    );

                    const countryCode = deploymentOptions?.getCloudRegion(
                        d?.getCloudProvider().code,
                        d.cloudRegion.code
                    );

                    return {
                        borderAlert: d.isStatusError(),
                        statusOverlayText: d.getDataStoreStatus(),
                        columns: [
                            {
                                content: (
                                    <DataStoreInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}DataStoreInformation`}
                                    />
                                ),
                                span: 5,
                            },
                            {
                                content: (
                                    <DataStoreStatus
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}Status`}
                                        updating={updating}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <DatabaseInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}DatabaseInformation`}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                content: (
                                    <CloudInformation
                                        dataStore={d}
                                        testId={`${testId}${d.dataStoreUuid}CloudInformation`}
                                        countryCode={countryCode}
                                    />
                                ),
                                span: 4,
                            },
                            {
                                align: 'center',
                                content:
                                    d.isDeploying() ||
                                    d.isDeleting() ||
                                    d.isStatusError() ||
                                    d.isStatusWarning() ? (
                                        <NoChartAvailable
                                            testId={`${testId}${d.dataStoreUuid}NoChartAvailable`}
                                        />
                                    ) : (
                                        <DataStoreChart dataStore={d} />
                                    ),
                                span: 5,
                            },
                            {
                                align: 'center',
                                content:
                                    ((d.isOperable() ||
                                        d.clusterStatus.isDeploymentFailed()) &&
                                        !d.isDeleting() &&
                                        !d.isDeploying()) ||
                                    d.isUnreachable() ? (
                                        <Dropdown
                                            data-testid={`${testId}${d.dataStoreUuid}Menu`}
                                            overlay={menu}
                                            placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                data-testid={`${testId}${d.dataStoreUuid}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${d.dataStoreUuid}MenuButtonIcon`}
                                                    />
                                                }
                                            ></Button>
                                        </Dropdown>
                                    ) : (
                                        // temporary
                                        <Dropdown
                                            data-testid={`${testId}${d.dataStoreUuid}Menu`}
                                            overlay={menu}
                                            placement="bottomRight"
                                            trigger={['click']}
                                            disabled={!d.isUnreachable()}
                                        >
                                            <Button
                                                data-testid={`${testId}${d.dataStoreUuid}MenuButton`}
                                                icon={
                                                    <CcxContextualMenuIcon
                                                        testId={`${testId}${d.dataStoreUuid}MenuButtonIcon`}
                                                    />
                                                }
                                            ></Button>
                                        </Dropdown>
                                    ),
                                span: 2,
                            },
                        ],
                        disabled: d.isDeleting(),
                    };
                })
            );
        }
    }, [datastores, selectedFilters]);

    useEffect(() => {
        setIsLoading(true);
        if (deploymentOptions) {
            onChangePage();
        }
    }, [deploymentOptions, selectedFilters]);

    const onChangePage = async (nextPage: number = pagination.page) => {
        const paginationData: PaginationProps = {
            perPage: pagination.perPage,
            page: nextPage,
        };

        setPagination(paginationData);

        await dispatch(getAllDataStores(processDataToDispatch(paginationData)));

        // scroll to top of the page upon page change
        window.scrollTo(0, 0);
        setIsLoading(false);
    };

    if (!isLoading && totalItems === 0) {
        return (
            <div className={styles.DataStoresEmpty}>
                <AppEmpty
                    testId={`${testId}Empty`}
                    message={
                        filterCount > 0
                            ? 'No datastores are matching the filter.'
                            : 'There are no datastores created yet.'
                    }
                >
                    {filterCount === 0 && (
                        <Link
                            to={`/projects/${projectUuid}/data-stores/add`}
                            data-testid={`${testId}CreateFirstLink`}
                        >
                            <Button data-testid={`${testId}CreateFirstButton`}>
                                Add your first datastore
                            </Button>
                        </Link>
                    )}
                </AppEmpty>
            </div>
        );
    }

    return (
        <>
            {isLoading && <LazyLoader type="row" />}
            {!isLoading && totalItems > 0 && (
                <AppGridTable
                    columns={columns}
                    rows={rows}
                    testId={testId}
                    isDatastore={true}
                />
            )}
            {!isLoading && totalItems > 10 && (
                <Row justify={'center'} style={{ marginTop: 16 }}>
                    <Col>
                        <Pagination
                            showSizeChanger={false}
                            defaultPageSize={pagination.perPage}
                            onChange={(nextPage: number) =>
                                onChangePage(nextPage)
                            }
                            defaultCurrent={pagination.page}
                            total={totalItems}
                        />
                    </Col>
                </Row>
            )}
        </>
    );
}

export default DataStores;
