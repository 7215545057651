import { useEffect, useState } from 'react';
import DataStoreLogsService from '../../services/DataStoreLogsService';
import { DbLog } from '../../types/DbLog';
import { DbLogsEntries } from '../../types/DbLogsEnteries';
import DbLogs from '../../types/DbLogs';

export default function useDataStoreEntries(
    dataStoreUuid?: string,
    record?: DbLogs
) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [dataStoreEntries, setDataStoreEntries] = useState<DbLogsEntries[]>();

    const load = async () => {
        if (dataStoreUuid && record) {
            setLoading(true);

            try {
                const results = await Promise.all(
                    record.files?.map(async (record: DbLog) => {
                        return await DataStoreLogsService.getDataStoreLogsEntries(
                            dataStoreUuid,
                            record.name
                        );
                    })
                );

                const combinedEntries: DbLogsEntries[] = results.flat();
                setDataStoreEntries(combinedEntries);
            } catch (e: any) {
                setError(e);
            } finally {
                setLoading(false);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        setLoading(true);
        setTimeout(() => {
            load();
        }, 1000);
    };

    useEffect(() => {
        refresh();
    }, [dataStoreUuid]);

    return { dataStoreEntries, refresh, loading, error };
}
