import { useEffect, useState } from 'react';
import PrometheusStatService from '../../services/PrometheusStatService';
import PrometheusDiskIopsStats from '../../types/PrometheusDiskIopsStats';

export default function usePrometheusDiskIopsStats(
    uuid: string,
    host_uuid?: string,
    from?: string,
    to?: string
) {
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [stats, setStats] = useState<PrometheusDiskIopsStats[] | undefined>(
        undefined
    );

    const load = async () => {
        if (uuid && host_uuid) {
            try {
                const details = await PrometheusStatService.getDiskIops(
                    uuid,
                    host_uuid,
                    from,
                    to
                );
                setStats(
                    details.map((d: any) => new PrometheusDiskIopsStats(d))
                );
                setError(null);
            } catch (e: any) {
                setError(e);
            }
            setUpdating(false);
            setLoading(false);
        }
    };

    const refresh = () => {
        setUpdating(true);
        load();
    };

    // get deployments for the given user
    useEffect(() => {
        const init = async () => {
            setLoading(true);
            await load();
        };

        init();
    }, [uuid, host_uuid, from, to]);

    return { stats, refresh, loading, updating, error };
}
