import ApiService from './ApiService';
import Backup from '../types/Backup';
import BackupSchedule from '../types/BackupSchedule';
import { API_BACKUP } from '../core/CcxEnv';
import BackupSettings from '../types/BackupSettings';
import Restore from '../types/Restore';
import {
    backupApiInterface,
    backupRestoreApiInterface,
    backupSettingsApiInterface,
} from '../sdk/BackupsApi';

// constants
const API_URL = API_BACKUP;

type CmonBackupsResponse = {
    backups: any[];
    total: number;
};

export default class BackupService extends ApiService {
    /**
     * Get list of Backups.
     * @param {string} uuid
     */
    static async getAllBackups(
        uuid: string,
        page: number,
        pageSize: number
    ): Promise<CmonBackupsResponse> {
        const response = await this.request(
            'GET',
            `backups/${uuid}?page=${page}&per_page=${pageSize}`
        );
        return {
            total: response.total,
            backups: response.backups.map((r: any) => new Backup(r)),
        };
    }

    /**
     * Get list of Backups.
     * @param {string} uuid
     */
    static async getFullBackups(uuid: string): Promise<Backup[]> {
        const response = await this.request('GET', `backups/${uuid}`);
        return response.map((r: any) => new Backup(r));
    }

    /**
     * Get list of Backups.
     * @param {number} parentId
     * @param {string} uuid
     */
    static async getIncrementalBackups(
        uuid: string,
        parentId: number,
        page: number,
        pageSize: number
    ): Promise<CmonBackupsResponse> {
        const { data } = await backupApiInterface.backupsDatastoreUuidGet(
            uuid,
            parentId,
            page,
            pageSize
        );
        return {
            total: data.total,
            backups: data.backups.map((r: any) => new Backup(r)),
        };
    }

    /**
     * Get list of Backup Schedules.
     * @param {string} uuid
     */
    static async getAllBackupSchedules(
        uuid: string
    ): Promise<BackupSchedule[]> {
        const { data } =
            await backupApiInterface.backupSchedulesDatastoreUuidGet(uuid);
        return data.map((r: any) => new BackupSchedule(r));
    }

    /**
     * Launches a restore backup job
     * @param {string} uuid
     * @param {string} backupId
     */
    static async restoreBackup(
        uuid: string,
        backupId: string | number,
        pitr_stop_time?: string | null
    ): Promise<any> {
        const postData = {
            data: {
                pitr_stop_time,
            },
        };

        return await this.request(
            'POST',
            `backups/${uuid}/${backupId}/restore`,
            postData
        );
    }

    static async getBackupRetention(uuid: string) {
        const { data }: any =
            await backupSettingsApiInterface.settingsDatastoreUuidRetentionGet(
                uuid
            );
        return data?.retention_days;
    }

    static async updateBackupRetention(uuid: string, retention: number) {
        return await backupSettingsApiInterface.settingsDatastoreUuidRetentionPost(
            uuid,
            {
                retention_days: retention,
            }
        );
    }

    /**
     * Get Backup settings.
     * @param {string} uuid
     */
    static async getBackupSettings(uuid: string): Promise<BackupSettings> {
        const { data }: any =
            await backupSettingsApiInterface.settingsDatastoreUuidGet(uuid);
        return new BackupSettings(data);
    }

    /**
     * Updates backup settings
     * @param {string} uuid
     * @param {any} data
     */
    static async updateBackupSettings(
        uuid: string,
        { full_frequency, full_start_hour, inc_frequency }: any
    ): Promise<any> {
        const postData = {
            data: {
                full_frequency,
                full_start_hour,
                inc_frequency,
            },
        };

        return await this.request('POST', `settings/${uuid}`, postData);
    }

    /**
     * Get Backup restores.
     * @param {string} uuid
     */
    static async getBackupRestores(uuid: string): Promise<Restore[]> {
        const { data } =
            await backupRestoreApiInterface.restoresDatastoreUuidGet(uuid);
        return data?.restores?.map((r: any) => new Restore(r)) || [];
    }

    protected static getApiUrl() {
        return API_URL;
    }
}
