import { Col, Row } from 'antd';
import AppTable from '../AppTable';
import styles from './EventsTable.module.css';
import Job from '../../types/Job';

interface EventsTableProps {
    eventColumns: any[];
    jobs: Job[];
}

const EventsTable = ({ eventColumns, jobs }: EventsTableProps) => {
    return (
        <>
            <Row justify={'space-between'}>
                <Col span={12}>
                    <h4 className={styles.Labels}>Events</h4>
                </Col>
            </Row>
            <AppTable
                columns={eventColumns}
                data={jobs}
                rowKey="jobId"
                pagination={{
                    hideOnSinglePage: true,
                    pageSize: 5,
                    position: ['bottomCenter'],
                }}
                expandable={false}
            />
        </>
    );
};

export default EventsTable;
