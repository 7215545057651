import Slider from 'rc-slider';
import styles from './DatastoreScalingStep2.module.less';
import 'rc-slider/assets/index.css';
import { Alert, Col, Row } from 'antd';
import DatastoreScalingStepHeader from './DatastoreScalingStepHeader';
import DatastoreScalingCard from './DatastoreScalingCard';
import DatastoreScalingNodeInfoCards, {
    DatastoreScalingNodeInfoCardsValues,
} from './DatastoreScalingNodeInfoCard';
import DeploymentsItem from '../../../types/DeploymentsItem';
import DeploymentOptions from '../../../types/DeploymentOptions';
import { useEffect, useState } from 'react';
import { Services } from '../../../services/ServiceService';

interface DataStoreScalingStep2Props {
    dataStore: DeploymentsItem;
    services: Services;
    deploymentOptions: DeploymentOptions;
    nodeValue: number;
    setNodeValue: Function;
    onUpdate?: (data: DatastoreScalingNodeInfoCardsValues) => void;
    testId?: string;
}

function nodeToString(count: number): string {
    return count === 1 ? `${count} Node` : `${count} Nodes`;
}

interface MarkItem {
    label: JSX.Element;
    value: number;
}

type Marks = Record<number, MarkItem>;

function makeMarks(
    dataStore: DeploymentsItem,
    numberOfNodes: number
): {
    marks: Marks;
    steps: number;
} {
    const sizes = dataStore.getScalingSizes();

    if (sizes.length <= 1) {
        return { marks: {}, steps: 0 };
    }

    const steps = Math.ceil(100 / (sizes.length - 1));
    const marks = sizes.reduce((p, value, i) => {
        const nodeText = nodeToString(value);

        const label =
            value === numberOfNodes ? (
                <>
                    <p>{nodeText}</p>
                    <p
                        className={
                            numberOfNodes === 1
                                ? styles.CurrentConfigurationText
                                : ''
                        }
                    >
                        (Current Configuration)
                    </p>
                </>
            ) : (
                <p>{nodeText}</p>
            );
        return {
            ...p,
            [i * steps]: { label, value },
        };
    }, {} as Marks);

    return { marks, steps };
}

export default function DatastoreScalingStep2({
    dataStore,
    services,
    deploymentOptions,
    nodeValue,
    setNodeValue,
    onUpdate,
    testId = 'DatastoreScalingStep2',
}: DataStoreScalingStep2Props) {
    const [marks, setMarks] = useState<Marks>({});
    const [step, setStep] = useState<number>(0);
    const [sliderValue, setSliderValue] = useState<number>(0);
    const [numberOfNodes, setNumberOfNodes] = useState<number>(
        services?.dbServices.length
    );
    const handleNodeChange = (sliderVal: number) => {
        if (typeof sliderVal !== 'number' || !marks || !marks[sliderVal]) {
            return;
        }

        const { value } = marks[sliderVal];

        setSliderValue(sliderVal);
        setNodeValue(value);
    };

    useEffect(() => {
        if (!dataStore) {
            return;
        }
        const { marks, steps } = makeMarks(dataStore, numberOfNodes);
        // get nodes and number of steps
        const maxNodes: any = dataStore.getScalingSizes().at(-1);
        const sliderVal = ((numberOfNodes - 1) / (maxNodes - 1)) * 100;
        const adjustedSliderVal = Math.min(100, Math.max(0, sliderVal));

        setMarks(marks);
        setStep(steps);
        setSliderValue(adjustedSliderVal);
    }, []);

    if (step === 0) {
        return (
            <Alert
                className={styles.DatastoreScalingStep2Alert}
                message={`Scaling is not supported for the cluster type "${dataStore?.getClusterType()}"`}
                showIcon
                type="info"
            />
        );
    }

    return (
        <div data-testid={testId}>
            {['galera', 'redis'].includes(
                dataStore?.getClusterType().toLowerCase()
            ) && (
                <Alert
                    className={styles.DatastoreScalingStep2Alert}
                    message={`For the cluster type "${dataStore?.getClusterType()}" you can only scale up/down by 2 nodes`}
                    showIcon
                    type="info"
                />
            )}
            <DatastoreScalingStepHeader
                title="Datastore configuration"
                subtitle="Use the slider to set the desired amount of nodes"
            >
                <Row className={styles.DatastoreScalingStep2SliderRow}>
                    <Col span={24} data-testid={`${testId}Slider`}>
                        <Slider
                            marks={marks}
                            defaultValue={sliderValue}
                            value={sliderValue}
                            trackStyle={{ backgroundColor: '#530099' }}
                            railStyle={{ backgroundColor: '#F5F5F5' }}
                            handleStyle={{ backgroundColor: 'white' }}
                            onChange={(e) =>
                                typeof e === 'number' && handleNodeChange(e)
                            }
                            step={step}
                            min={0}
                            max={100}
                        />
                    </Col>
                </Row>
                <DatastoreScalingCard
                    stepScalingTitle="Configuration: "
                    stepScalingCount={nodeToString(nodeValue)}
                />
                <Row className={styles.DatastoreScalingStep2NodeInfo}>
                    <DatastoreScalingNodeInfoCards
                        services={services}
                        numNodes={nodeValue}
                        dataStore={dataStore}
                        deploymentOptions={deploymentOptions}
                        onUpdate={onUpdate}
                    />
                </Row>
            </DatastoreScalingStepHeader>
        </div>
    );
}
