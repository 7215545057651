import { ReactElement } from 'react';
import AppEmpty from '../AppEmpty';
import { useParams } from 'react-router-dom';
import AppLoading from '../AppLoading';
import DeploymentsItem from '../../types/DeploymentsItem';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';
import Job from '../../types/Job';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import useDataStoreServices from '../../core/hooks/useDataStoreServices';
import DbService from '../../types/DbService';
import styles from './EventViewer.module.less';
import EventStatus from './EventStatus';
import DatastoreLogs from '../logs/DatastoreLogs';
import useDataStoreLogs from '../../core/hooks/useDataStoreLogs';
import EventsTable from './EventsTable';

interface UrlProps {
    dataStoreUuid: string;
}

type EventViewerProps = {
    currentDeployment?: DeploymentsItem;
    testId?: string;
};

function EventViewer({
    currentDeployment,
    testId = 'EventViewer',
}: EventViewerProps): ReactElement {
    const { dataStoreUuid } = useParams<UrlProps>();
    const { services: nodes, loading: loadingNodes } = useDataStoreServices({
        dataStoreUuid,
    });

    const { loading: loadingLogs, dataStoreLogs } =
        useDataStoreLogs(dataStoreUuid);

    const { jobs, loading } = useDataStoreJobs({
        dataStoreUuid,
    });

    const formatDate = (date: string) => {
        return new FriendlyDateTime({
            date,
        }).getDate();
    };

    const eventColumns = [
        {
            title: 'When',
            render: (record: Job) => {
                return formatDate(record.getDateForEventViewer());
            },
            sorter: (a: any, b: any) => {
                return (
                    new Date(a.getDateForEventViewer()).getTime() -
                    new Date(b.getDateForEventViewer()).getTime()
                );
            },
            key: 'when',
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: Job) => {
                return (
                    <EventStatus
                        type={record.getStatusType()}
                        showTooltips={false}
                        job={record}
                    />
                );
            },
        },
        {
            title: 'Initiated by',
            key: 'user',
            render: (record: Job) => {
                if (currentDeployment?.accountId === record.user) return 'User';

                return 'System';
            },
        },
        {
            title: 'Description',
            key: 'type',
            render: (record: Job) => {
                const relatedNode = nodes?.dbServices?.find(
                    (node: DbService) => {
                        return (
                            node.getServiceUuid() === record.data?.HostUUID ||
                            node.getHostnameOrIP() === record.data?.HostName
                        );
                    }
                );

                return record.getDescription(relatedNode);
            },
        },
    ];

    return (
        <section data-testid={testId} className={styles.EventViewer}>
            {loading || loadingNodes || loadingLogs ? (
                <AppLoading />
            ) : jobs?.length && nodes?.dbServices?.length ? (
                <EventsTable eventColumns={eventColumns} jobs={jobs} />
            ) : (
                <AppEmpty message="There are no jobs created yet" />
            )}
            {dataStoreLogs?.files.length ? (
                <DatastoreLogs
                    currentDeployment={currentDeployment}
                    dataStoreUuid={dataStoreUuid}
                    logs={dataStoreLogs || []}
                />
            ) : (
                <></>
            )}
        </section>
    );
}

export default EventViewer;
