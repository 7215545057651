import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row, message, notification } from 'antd';
import CcxComponentProps from '../../../../core/CcxComponent';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import ProvisionService from '../../../../services/ProvisionService';
import CcxIconInfoCircleTwoTone from '../../../ccx/icons/CcxIconInfoCircleTwoTone';
import CcxIconCloseCircleTwoTone from '../../../ccx/icons/CcxIconCloseCircleTwoTone';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import PrettyNumber from '../../../ccx/common/PrettyNumber';
import DatastoreScalingInfoCard from '../DatastoreScalingInfoCard';
import DatastoreScalingStep2 from '../DatastoreScalingStep2';
import { Services } from '../../../../services/ServiceService';
import { billingDisabled } from '../../../../core/CcxEnv';
import DeploymentOptions from '../../../../types/DeploymentOptions';
import {
    DatastoreScalingNodeInfoCardsValues,
    ScalingType,
} from '../DatastoreScalingNodeInfoCard';
import DbService from '../../../../types/DbService';
import useDataStoreJobs from '../../../../core/hooks/useDataStoreJobs';
import styles from './DataStoreScaleNodeModal.module.less';
import { useAppSelector } from '../../../../redux/hooks';

interface Props extends CcxComponentProps {
    datastore: DeploymentsItem;
    pendingJobs: any[];
    services: Services;
    refresh: () => void;
    clearStoreInterval: () => void;
    startStoreInterval: () => void;
    setIsButtonDisabled: Function;
    isButtonDisabled: boolean;
    nodes: any[];
    isScalingModalVisible: boolean;
    setIsScalingModalVisible: Function;
}

function ScaleNodeModal({
    testId = 'ScaleNodeModal',
    datastore,
    pendingJobs,
    nodes,
    services,
    refresh,
    clearStoreInterval,
    startStoreInterval,
    setIsButtonDisabled,
    isButtonDisabled,
    isScalingModalVisible,
    setIsScalingModalVisible,
}: Props) {
    const [storage] = useState<number>(datastore?.volumeSize);

    const [nodeValue, setNodeValue] = useState<number>();

    const { refresh: refreshJobs } = useDataStoreJobs({
        dataStoreUuid: datastore?.getUUID(),
    });

    const [scalingType, setScalingType] = useState<ScalingType>('none');

    const dataStoreUuid = datastore?.dataStoreUuid;
    const [monthlyCost, setMonthlyCost] = useState(0);
    const [countryCode, setCountryCode] = useState<string | undefined>(
        undefined
    );
    const [checkedValues, setCheckedValues] = useState<string[]>([]);
    const [newNodeData, setNewNodeData] = useState<any[]>([]);

    const { deploymentOptions } = useAppSelector(
        (state) => state.deploymentOptions
    );

    useEffect(() => {
        if (!nodeValue) setNodeValue(services?.dbServices.length);
    }, [services]);

    const onUpdate = (data: DatastoreScalingNodeInfoCardsValues) => {
        setCheckedValues(data.node_uuids);
        setNewNodeData(data.add_nodes);
        setScalingType(data.scaling_type);
    };

    useEffect(() => {
        return () => {
            clearStoreInterval();
        };
    }, []);

    const onClose = () => {
        setIsScalingModalVisible(false);
        clearStoreInterval();
    };

    useEffect(() => {
        setCheckedValues([]);
    }, [nodeValue]);

    const handleSubmit = async () => {
        if (
            scalingType !== 'none' &&
            (checkedValues?.length !== 0 || newNodeData?.length !== 0)
        ) {
            try {
                if (scalingType === 'down' && nodeValue) {
                    if (
                        checkedValues.length ===
                        services?.dbServices.length - nodeValue
                    ) {
                        await ProvisionService.scaleDownDatastoreNodes({
                            dataStoreUuid,
                            nodeUuids: checkedValues,
                        });

                        refreshJobs();
                    } else {
                        return message.error(
                            'Please select all nodes to scale down'
                        );
                    }
                } else if (scalingType === 'up') {
                    const newArr = newNodeData.map((obj) => {
                        const { availability_zone, instance_size } = obj;
                        return { availability_zone, instance_size };
                    });

                    await ProvisionService.scaleUpDatastoreNodes({
                        dataStoreUuid,
                        nodeData: newArr,
                    });

                    refreshJobs();
                }

                setNewNodeData([]);
                setCheckedValues([]);
                setIsButtonDisabled(true);

                notification.open({
                    message: 'Datastore configuration',
                    description:
                        'Your datastore configuration will updated shortly.',
                    icon: <CcxIconInfoCircleTwoTone />,
                });

                onClose();
                setTimeout(() => {
                    setIsButtonDisabled(false);
                }, 5000);
            } catch (error) {
                notification.open({
                    message: 'Error',
                    description: `An error occurred while processing the requests. ${error}`,
                    icon: <CcxIconCloseCircleTwoTone twoToneColor="#eb2f96" />,
                });
            }
        } else {
            message.error('One or more required fields are missing');
        }
    };

    const getPriceByType = (t: string) => {
        const deployOption = deploymentOptions?.getInstanceSizes(
            datastore?.cloudProvider
        );
        const option = deployOption?.find((option) => option.type === t);
        return option ? option.price : null;
    };

    const newNodeDataWithPrices = useMemo(() => {
        if (newNodeData) {
            const updatedPrices = newNodeData.reduce((acc, node) => {
                const { instance_size, index_no } = node;
                const price = getPriceByType(instance_size);

                // Check if an object with the same index_no exists in acc
                const existingIndex = acc.findIndex(
                    (item: { index_no: any }) => item.index_no === index_no
                );

                if (existingIndex !== -1) {
                    // Replace the existing object with the new one
                    acc[existingIndex] = { ...node, price };
                } else {
                    // Add the new object to the array
                    acc.push({ ...node, price });
                }

                return acc;
            }, []);

            return updatedPrices;
        }
        return undefined;
    }, [newNodeData]);

    const sumOfInstancesPrice = useMemo(() => {
        if (newNodeData) {
            return newNodeDataWithPrices?.reduce(
                (accumulator: any, current: { price: any }) =>
                    accumulator + (current.price || 0),
                0
            );
        }
        return undefined;
    }, [newNodeData, newNodeDataWithPrices]);

    const numberOfNodes =
        services?.dbServices?.length + newNodeDataWithPrices?.length;

    const getVolumeTypePrice = (
        deploymentOptions: DeploymentOptions | undefined,
        datastore: DeploymentsItem
    ) => {
        const volumeTypes =
            deploymentOptions?.instance?.volumeTypes[
                datastore?.cloudProvider?.code
            ];

        const volumeTypePrice = volumeTypes?.find(
            (v: any) => v.code === datastore?.volumeType
        )?.price;

        return volumeTypePrice / 100;
    };

    useEffect(() => {
        const instanceMonthlyPrice = (sumOfInstancesPrice / 100) * 730 || 0;
        const instanceNumber = numberOfNodes || 0;
        const storageMonthlyPrice =
            getVolumeTypePrice(deploymentOptions, datastore) || 0;
        const numberOfGB = storage || 0;
        const iopsMonthlyPrice = 0;
        const numberOfIOPS = 0;

        const monthlyPriceEstimate =
            instanceMonthlyPrice * instanceNumber +
            storageMonthlyPrice * instanceNumber * numberOfGB +
            iopsMonthlyPrice * instanceNumber * numberOfIOPS;

        setMonthlyCost(parseFloat(monthlyPriceEstimate.toFixed(2)));
    }, [getVolumeTypePrice, numberOfNodes, storage, sumOfInstancesPrice]);

    return (
        <Modal
            title={'Scale Nodes'}
            open={isScalingModalVisible}
            className={styles.ScaleNodesModal}
            data-testid={`${testId}Modal`}
            width={800}
            onCancel={onClose}
            footer={
                <Row justify={billingDisabled ? 'end' : 'space-between'}>
                    {!billingDisabled && (
                        <Col>
                            <div>
                                Estimated total cost{' '}
                                <InfoIcon
                                    info={
                                        <span>
                                            This amount will be added to your
                                            next invoice.
                                        </span>
                                    }
                                />{' '}
                            </div>
                            <div
                                style={{
                                    color: '#46bd15',
                                    fontWeight: 'bold',
                                    display: 'flex',
                                }}
                            >
                                <PrettyNumber
                                    prefix={<>&#36;</>}
                                    value={monthlyCost}
                                    sufix={' / month'}
                                />
                            </div>
                        </Col>
                    )}
                    <Col>
                        <Button
                            data-testid={`${testId}CancelButton`}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>

                        <Button
                            data-testid={`${testId}SubmitButton`}
                            onClick={handleSubmit}
                            type="primary"
                            disabled={
                                datastore?.isRedis() &&
                                (checkedValues?.length % 2 !== 0 ||
                                    newNodeData?.length % 2 !== 0)
                            }
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            }
        >
            <DatastoreScalingInfoCard
                dataStore={datastore}
                setCountryCode={setCountryCode}
                countryCode={countryCode}
            />

            <DatastoreScalingStep2
                dataStore={datastore}
                services={services}
                deploymentOptions={deploymentOptions!}
                nodeValue={nodeValue!}
                setNodeValue={setNodeValue}
                onUpdate={onUpdate}
            />
        </Modal>
    );
}

export default ScaleNodeModal;
