import { useEffect, useState } from 'react';
import DataStoreLogsService from '../../services/DataStoreLogsService';
import DbLogs from '../../types/DbLogs';

export default function useDataStoreLogs(dataStoreUuid?: string) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [dataStoreLogs, setDataStoreLogs] = useState<DbLogs>();

    const load = async () => {
        if (dataStoreUuid) {
            setLoading(true);

            try {
                const result = await DataStoreLogsService.getDataStoreLogs(
                    dataStoreUuid
                );
                setDataStoreLogs(result);
                setLoading(false);
                setError(null);
            } catch (e: any) {
                setLoading(false);
                setError(e);
            }

            setLoading(false);
        }
    };

    const refresh = () => {
        setLoading(true);
        setTimeout(() => {
            load();
        }, 1000);
    };

    useEffect(() => {
        refresh();
    }, [dataStoreUuid]);

    return { dataStoreLogs, refresh, loading, error };
}
