import { Form } from 'antd';
import InstanceSize from '../../../types/InstanceSize';
import AppRadioGroupGrid, {
    AppRadioGridButton,
} from '../../ccx/common/Form/AppRadioGroupGrid';
import InstanceCard from './InstanceCard';
import InstanceVolumeTypeForm from './formParts/InstanceVolumeTypeForm';
import { ReactElement, useEffect, useState } from 'react';
import DeploymentOptions from '../../../types/DeploymentOptions';
import CloudProvider from '../../../types/CloudProvider';
import User from '../../../types/User';
import { FormInstance } from 'antd/lib/form';
import InstanceVolumeSize from '../../../types/InstanceVolumeSize';
import InstanceVolumeType from '../../../types/InstanceVolumeType';
import InstanceVolumeIopsValue from '../../../types/InstanceVolumeIopsValue';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DatabaseVendor from '../../../types/DatabaseVendor';
import { useAppSelector } from '../../../redux/hooks';

export type Props = {
    testId: string;
    selectedTech?: DatabaseVendor;
    handleInstanceSizeChange: Function;
    deploymentOptions?: DeploymentOptions;
    cloudProvider: CloudProvider | undefined;
    handleVolumeTypeChange: Function;
    form: FormInstance;
    volumeSize: InstanceVolumeSize | undefined;
    volumeType: InstanceVolumeType | undefined;
    volumeIops: InstanceVolumeIopsValue | undefined;
    enteredVolumeSize: number | undefined;
    setEnteredVolumeSize: Function;
    setEnteredIOPS: Function;
    instance: InstanceSize | undefined;
    handleVolumeIopsChange: Function;
};

export default function InstanceSizeForm({
    testId,
    selectedTech,
    handleInstanceSizeChange,
    deploymentOptions,
    cloudProvider,
    form,
    handleVolumeTypeChange,
    volumeIops,
    volumeSize,
    volumeType,
    enteredVolumeSize,
    setEnteredVolumeSize,
    setEnteredIOPS,
    instance,
    handleVolumeIopsChange,
}: Props): ReactElement {
    const { isBackupRestore, restoreDatastore } = useAppSelector(
        (state) => state.restoreBackup
    );
    const onVolumeSizeChange = (value?: number) => {
        setEnteredVolumeSize && setEnteredVolumeSize(value);
    };

    const onIOPSChange = (value?: number) => {
        setEnteredIOPS && setEnteredIOPS(value);
    };

    useEffect(() => {
        if (
            isBackupRestore &&
            restoreDatastore &&
            deploymentOptions &&
            cloudProvider
        ) {
            const instanceSizes =
                deploymentOptions.getInstanceSizes(cloudProvider);
            const selectedInstanceSize = instanceSizes.find(
                (size) => size.type === restoreDatastore.instanceSize
            );
            const volumeTypes = deploymentOptions.getVolumeTypes(cloudProvider);
            const selectedVolumeType = volumeTypes.find(
                (type) => type.code === restoreDatastore.volumeType
            );

            handleInstanceSizeChange({
                target: { value: selectedInstanceSize },
            });

            if (selectedVolumeType) {
                handleVolumeTypeChange({
                    target: { value: selectedVolumeType },
                });
            }

            setEnteredVolumeSize(restoreDatastore.volumeSize);

            form.setFieldsValue({
                instanceSize: selectedInstanceSize,
                volumeType: selectedVolumeType,
                volumeSize: restoreDatastore.volumeSize,
                volumeIops: restoreDatastore.volumeIops,
            });
        }
    }, [isBackupRestore, restoreDatastore, deploymentOptions]);

    return (
        <>
            <p>
                <strong>Instances</strong>
                <InfoIcon
                    info={
                        <span>
                            Select the instance matching your needs. Instances
                            types can be changed later.
                        </span>
                    }
                />
            </p>

            <Form.Item
                name="instanceSize"
                label=""
                rules={[
                    {
                        required: true,
                        message: 'Select your preferred instance',
                    },
                ]}
            >
                <AppRadioGroupGrid
                    data-testid={`${testId}InstanceSizeRadio`}
                    onChange={handleInstanceSizeChange as any}
                    items={
                        (cloudProvider &&
                            deploymentOptions
                                ?.getInstanceSizes(cloudProvider)
                                ?.map((s: InstanceSize) => {
                                    return (
                                        <AppRadioGridButton
                                            key={s.code}
                                            data-testid={`${testId}InstanceSizeRadio${s.code}`}
                                            value={s}
                                            size={'large'}
                                        >
                                            <InstanceCard instance={s} />
                                        </AppRadioGridButton>
                                    );
                                })) ||
                        []
                    }
                />
            </Form.Item>

            <InstanceVolumeTypeForm
                form={form}
                selectedTech={selectedTech}
                deploymentOptions={deploymentOptions}
                cloudProvider={cloudProvider}
                handleVolumeTypeChange={handleVolumeTypeChange}
                volumeSize={volumeSize}
                volumeType={volumeType}
                volumeIops={volumeIops}
                enteredVolumeSize={enteredVolumeSize}
                testId={testId}
                onVolumeSizeChange={onVolumeSizeChange}
                onIOPSChange={onIOPSChange}
            />
        </>
    );
}
