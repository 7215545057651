import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../slices/user.slice';
import dataStoresReducer from '../slices/datastores.slice';
import jobsReducer from '../slices/jobs.slice';
import cmonReducer from '../slices/cmon.slice';
import deploymentOptionsReducer from '../slices/deploymentOptions.slice';
import dataStoreReducer from '../slices/datastore.slice';
import restoreBackupReducer from '../slices/restore.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        datastores: dataStoresReducer,
        jobs: jobsReducer,
        cmon: cmonReducer,
        deploymentOptions: deploymentOptionsReducer,
        dataStore: dataStoreReducer,
        restoreBackup: restoreBackupReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunk: true,
        }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
