import { createSlice } from '@reduxjs/toolkit';
import { AddDeploymentV2Props } from '../services/ProvisionService';

interface RestoreDataStoreType {
    restoreDatastore: AddDeploymentV2Props | undefined;
    isBackupRestore: boolean;
}
const initialState: RestoreDataStoreType = {
    restoreDatastore: undefined,
    isBackupRestore: false,
};

export const restoreDatastoreSlice = createSlice({
    name: 'datastore',
    initialState,
    reducers: {
        restoreDatastoreFromBackup: (state, action) => {
            state.isBackupRestore = true;
            state.restoreDatastore = action.payload;
        },
        setInitialState: (state) => {
            state.isBackupRestore = false;
            state.restoreDatastore = undefined;
        },
    },
});

export const { restoreDatastoreFromBackup, setInitialState } =
    restoreDatastoreSlice.actions;
export default restoreDatastoreSlice.reducer;
