import useBackups from '../../core/hooks/useBackups';
import { useEffect, useState } from 'react';
import AppLoading from '../AppLoading';
import AppTable from '../AppTable';
import AppEmpty from '../AppEmpty';
import BackupStatus from './BackupStatus';
import FriendlyDateTime from '../../types/FriendlyDateTime';
import ExpandableText from '../ExpandableText';
import numbro from 'numbro';
import DeploymentsItem from '../../types/DeploymentsItem';
import Restore from '../../types/Restore';
import useBackupRestores from '../../core/hooks/useBackupRestores';
import IncrementalBackups from './IncrementalBackups';
import Backup from '../../types/Backup';
import BackupsModal from './BackupsModal';
import RestoringBackups from './RestoringBackups';
import BackupsListActionsMenu from './BackupsListActionsMenu';
import Job, { TYPE_RESTORE_BACKUP } from '../../types/Job';
import useDataStoreJobs from '../../core/hooks/useDataStoreJobs';
import { getNonErroredJobs } from '../../core/helpers';

type BackupsListProps = {
    deployment: DeploymentsItem;
    setIsBackupInProcess: Function;
};
export default function BackupsList({
    deployment,
    setIsBackupInProcess,
}: BackupsListProps) {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentRestore, setCurrentRestore] = useState<Restore>();
    const [restoreObj, setRestoreObj] = useState<Backup>();
    const [openRestore, setOpenRestore] = useState<boolean>(false);
    const { jobs } = useDataStoreJobs({
        dataStoreUuid: deployment.dataStoreUuid,
    });
    const {
        backups,
        loading: backupsLoading,
        page,
        pageSize,
        total,
    } = useBackups(deployment.dataStoreUuid, currentPage, 20);

    const { backupRestores, refresh: refreshRestores } = useBackupRestores(
        deployment.dataStoreUuid
    );

    useEffect(() => {
        if (backups?.length > 0 && backupRestores?.length > 0) {
            let restore: Restore = backupRestores[0];

            if (!restore.backupType) {
                const backup = (backups as Backup[]).find(
                    (b) => b.backupId === restore.backupId
                );

                if (backup) {
                    restore = new Restore({
                        cmon_backup_id: restore.backupId,
                        progress: restore.progress,
                        status: restore.status,
                        status_text: restore.statusText,
                        created_at: restore.createdAt,
                        started_at: restore.startedAt,
                        updated_at: restore.updatedAt,
                        ended_at: restore.endedAt,
                        backup: {
                            backup_type: backup.backupType,
                            size: backup.size,
                        },
                    });
                }
            }

            setCurrentRestore(restore);
        }
    }, [backupRestores, backups]);

    useEffect(() => {
        const AUTO_REFRESH_INTERVAL = 20000;
        const interval = setInterval(async () => {
            refreshRestores();
        }, AUTO_REFRESH_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    const handleTableChange = async (pagination: any) => {
        setCurrentPage(pagination.current);
    };

    const backupsColumns = [
        {
            title: 'Serial',
            key: 'serial',
            render: (text: number, record: any, index: number) => {
                return `${index + 1}`;
            },
        },
        {
            title: 'Method',
            dataIndex: 'backupMethod',
            key: 'backupMethod',
        },
        {
            title: 'Type',
            dataIndex: 'backupType',
            key: 'backupType',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: any) => {
                return (
                    <BackupStatus backup={record} data-testid="BackupStatus" />
                );
            },
        },
        {
            title: 'Started',
            dataIndex: 'startedAt',
            key: 'startedAt',
            width: 230,
            render: (text: string, record: any) => {
                const a = new FriendlyDateTime({
                    date: record.startedAt,
                });
                return (
                    <ExpandableText
                        collapsedText={a?.getFullDate()}
                        expandedText={a?.getFriendlyDate()}
                    />
                );
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            width: 80,
            render: (text: string, record: any) => {
                const from = new Date(record.startedAt);
                const to = new Date(record.endedAt);
                const diff = to.getTime() - from.getTime();
                const secs = diff / 1000;
                let duration;
                if (secs > 59) {
                    duration = `${(secs / 60).toFixed()} mins`;
                } else {
                    duration = `${secs.toFixed()} secs`;
                }

                return (
                    <ExpandableText
                        collapsedText={duration}
                        expandedText={new FriendlyDateTime({
                            date: record.endedAt,
                        }).getFullDate()}
                    />
                );
            },
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            width: 120,
            render: (text: string, record: any) => {
                return numbro(parseInt(record.size, 10)).format({
                    output: 'byte',
                    base: 'binary',
                    spaceSeparated: true,
                    mantissa: 2,
                });
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 85,
            render: (text: string, record: any) => (
                <BackupsListActionsMenu
                    deployment={deployment}
                    record={record}
                    disabled={determineButtonStatus()}
                    setOpenRestore={setOpenRestore}
                    setRestoreObj={setRestoreObj}
                    currentRestore={currentRestore}
                />
            ),
        },
    ];

    const determineButtonStatus = () => {
        const runningJobs = getNonErroredJobs(jobs);
        const isBackupJobRunning = runningJobs?.some(
            (job: Job) => job.type === TYPE_RESTORE_BACKUP
        );
        let isButtonDisabled = !deployment?.isOperable();
        if (currentRestore?.isStatusError() && isBackupJobRunning) {
            isButtonDisabled = false;
        }
        if (isBackupJobRunning) {
            isButtonDisabled = true;
        }
        setIsBackupInProcess(isButtonDisabled);
        return isButtonDisabled;
    };

    const rowExpandable = (record: any) => {
        // row will be expandable if the backup has children
        return record.hasChildren;
    };

    const expandedRowRender = (record: any) => {
        return (
            <IncrementalBackups
                dataStoreUuid={deployment.dataStoreUuid}
                parentId={record.backupId}
                restoring={
                    currentRestore &&
                    !(
                        currentRestore?.isStatusCompleted() ||
                        currentRestore?.isStatusError()
                    )
                }
            />
        );
    };

    return backupsLoading ? (
        <AppLoading />
    ) : backups?.length > 0 ? (
        <section>
            {currentRestore && !currentRestore?.isClosed() && (
                <RestoringBackups currentRestore={currentRestore} />
            )}

            <AppTable
                columns={backupsColumns}
                data={backups}
                rowKey="backupId"
                expandedRowRender={expandedRowRender}
                rowExpandable={rowExpandable}
                pagination={{
                    pageSize,
                    current: page,
                    total,
                    hideOnSinglePage: true,
                }}
                onChange={handleTableChange}
                customIcon={true}
            />

            <BackupsModal
                visible={openRestore}
                currentBackup={restoreObj}
                dataStore={deployment.dataStoreUuid}
                onclose={(submitted) => {
                    setOpenRestore(false);
                    submitted && refreshRestores();
                }}
            />
        </section>
    ) : (
        <AppEmpty message="There are no Backups to display yet." />
    );
}
