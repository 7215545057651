export type PrometheusPostgreSqlReplicaLagStatsBackendApiProps = {
    created: number;
    instance: number;
    replica_lag: number;
};

export default class PrometheusPostgreSqlReplicaLagStats {
    readonly created: number;
    readonly instance: number;
    readonly replicaLag: number;

    constructor(props: PrometheusPostgreSqlReplicaLagStatsBackendApiProps) {
        this.created = props.created;
        this.instance = props.instance;
        this.replicaLag = props.replica_lag;
    }
}
