interface DbLogEntryInterface {
    filename: string;
    hostname: string;
    message: string;
    timestamp: string;
}

export class DbLogEntry implements DbLogEntryInterface {
    readonly filename: string;
    readonly hostname: string;
    readonly message: string;
    readonly timestamp: string;

    constructor(props: DbLogEntryInterface) {
        this.filename = props.filename;
        this.hostname = props.hostname;
        this.message = props.message;
        this.timestamp = props.timestamp;
    }
}
