import PrometheusReplicaLag from './PrometheusReplicaLag';

export interface PrometheusReplicaLagDataInterface {
    PrometheusReplicaLag: PrometheusReplicaLag[];
}

export default class PrometheusReplicaLagData
    implements PrometheusReplicaLagDataInterface
{
    readonly PrometheusReplicaLag: PrometheusReplicaLag[];

    constructor(props?: PrometheusReplicaLag[]) {
        this.PrometheusReplicaLag =
            props?.map((d: any) => {
                return new PrometheusReplicaLag(d);
            }) || [];
    }
}
