import { Alert, Badge, Button, Popconfirm } from 'antd';
import { Form, Row, Col } from 'antd';
import { Select } from 'antd';
import { ReactElement, useEffect } from 'react';
import CcxComponentProps from '../../../../core/CcxComponent';
import styles from './DataStoreSettingsMaintenance.module.less';
import './DatastoreSettingsDbParameters.less';
import { CheckCircleTwoTone } from '@ant-design/icons';
import DeploymentsItem from '../../../../types/DeploymentsItem';
import DataStoreMaintenanceTooltip from './DataStoreMaintenanceTooltip';

const timeSlots = Array(24)
    .fill({ label: '', value: 0 })
    .map((_, index): { label: string; value: number } => ({
        label: index < 10 ? `0${index}:00` : `${index}:00`,
        value: index,
    }));

interface Props extends CcxComponentProps {
    form: any;
    maintenanceDayOfWeek?: number;
    maintenanceStartHour?: number;
    onDayOfWeekChange: Function;
    onTimeWindowChange: Function;
    canUpgradeTo: string | undefined;
    handleUpgradeTo: () => Promise<void>;
    filteredJob: any;
    currentDeployment?: DeploymentsItem | null | undefined;
}

function DataStoreSettingsMaintenance({
    currentDeployment,
    form,
    maintenanceDayOfWeek,
    maintenanceStartHour,
    onDayOfWeekChange,
    onTimeWindowChange,
    canUpgradeTo,
    handleUpgradeTo,
    filteredJob,
    testId = 'DataStoreSettingsMaintenance',
}: Props): ReactElement {
    useEffect(() => {
        if (maintenanceDayOfWeek) form.setFieldsValue({ maintenanceDayOfWeek });
        if (maintenanceStartHour && maintenanceStartHour >= 0) {
            form.setFieldsValue({ maintenanceStartHour });
        }
    }, [maintenanceDayOfWeek, maintenanceStartHour]);

    const handleOnDayOfWeekChange = (value: number) => {
        if (maintenanceDayOfWeek !== value) {
            onDayOfWeekChange && onDayOfWeekChange(value);
        }
    };

    const handleOnTimeWindowChange = (value: number) => {
        if (maintenanceStartHour !== value) {
            onTimeWindowChange && onTimeWindowChange(value, value + 2);
        }
    };

    const dataStoreMaintenanceCard = () => {
        return (
            <>
                {canUpgradeTo ? (
                    <>
                        <div className={styles.DataStoreSettingsUpgradeLabel}>
                            Upgrades available
                        </div>
                        <div
                            className={
                                styles.DataStoreSettingsMaintenanceDescription
                            }
                        >
                            There is a pending upgrade,{' '}
                            <b>select a time and date</b> when the upgrade will
                            be started or select <b>Upgrade now</b> to perform
                            the upgrade immediately.
                        </div>
                        <div
                            className={
                                styles.DataStoreSettingsMaintenanceDescription
                            }
                        >
                            Note: The update will be performed using a
                            roll-forward upgrade algorithm.
                            <DataStoreMaintenanceTooltip />
                        </div>
                    </>
                ) : (
                    <div
                        className={
                            styles.DataStoreSettingsMaintenanceDescription
                        }
                    >
                        Please select your preferred time settings when we could
                        work on regular maintenance.
                    </div>
                )}
                <Form form={form} layout="vertical">
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item noStyle>
                                <Form.Item
                                    name="maintenanceDayOfWeek"
                                    label="Day of the week"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a day',
                                        },
                                    ]}
                                >
                                    <Select
                                        data-testid={`${testId}DataStoreDayOfWeekSelect`}
                                        placeholder="Select a day"
                                        size="large"
                                        onChange={handleOnDayOfWeekChange}
                                    >
                                        <Select.Option value={7}>
                                            Sunday
                                        </Select.Option>
                                        <Select.Option value={1}>
                                            Monday
                                        </Select.Option>
                                        <Select.Option value={2}>
                                            Tuesday
                                        </Select.Option>
                                        <Select.Option value={3}>
                                            Wednesday
                                        </Select.Option>
                                        <Select.Option value={4}>
                                            Thursday
                                        </Select.Option>
                                        <Select.Option value={5}>
                                            Friday
                                        </Select.Option>
                                        <Select.Option value={6}>
                                            Saturday
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item noStyle>
                                <Form.Item
                                    name="maintenanceStartHour"
                                    label="Starting time"
                                    extra="Time is displayed in UTC"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select a time window',
                                        },
                                    ]}
                                >
                                    <Select
                                        data-testid={`${testId}DataStoreTimeWindow`}
                                        placeholder="Select a time window"
                                        size="large"
                                        onChange={handleOnTimeWindowChange}
                                    >
                                        {timeSlots?.map(
                                            (t: {
                                                label: string;
                                                value: number;
                                            }) => {
                                                return (
                                                    <Select.Option
                                                        value={t.value}
                                                    >
                                                        {t.label}
                                                    </Select.Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                            </Form.Item>
                        </Col>
                        {canUpgradeTo && (
                            <>
                                <Col span={8}>
                                    <div
                                        className={
                                            styles.DataStoreSettingsUpgradeButton
                                        }
                                    >
                                        <span>or</span>
                                        <Popconfirm
                                            title="Are you sure you want to upgrade datastore?"
                                            okText="Upgrade"
                                            cancelText="Cancel"
                                            placement="topRight"
                                            overlayInnerStyle={{
                                                padding: 0,
                                                borderRadius: 16,
                                            }}
                                            overlayStyle={{
                                                maxWidth: 500,
                                                minWidth: 400,
                                            }}
                                            onConfirm={handleUpgradeTo}
                                            disabled={filteredJob?.length > 0}
                                        >
                                            <Button
                                                type="primary"
                                                disabled={
                                                    filteredJob?.length > 0 ||
                                                    !currentDeployment?.isOperable
                                                }
                                            >
                                                Upgrade now
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </Form>
            </>
        );
    };

    return (
        <div
            className={styles.DataStoreSettingsMaintenance}
            data-testid={testId}
        >
            <h2 data-testid={`${testId}Title`}>
                <span className={styles.DataStoreSettingsMaintenanceLabel}>
                    Maintenance
                </span>{' '}
                {canUpgradeTo && <Badge count={1} />}
            </h2>

            {canUpgradeTo ? (
                <Alert
                    className={styles.DataStoreSettingsMaintenanceAlert}
                    message={dataStoreMaintenanceCard()}
                    showIcon
                    type="info"
                />
            ) : (
                <>
                    <div className={styles.DataStoreSettingsUpToDate}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                        <span>Your system is up to date</span>
                    </div>
                    {dataStoreMaintenanceCard()}
                </>
            )}
        </div>
    );
}

export default DataStoreSettingsMaintenance;
