import ApiService from './ApiService';
import { API_PROV, API_PROV_V2 } from '../core/CcxEnv';
import { CloudRegion } from '../types/DeploymentsItem';
import DataStoreEmailNotificationsSettings from '../types/DataStoreEmailNotifications';

type AddDeploymentProps = {
    clusterName: string;
    clusterType: string;
    cloudProvider: string;
    region: string;
    dbVendor: string;
    instanceSize: string;
    deploymentSize: number;
    vpcUuid: string;
    diskSize: number;
    iops: number;
};

export type AddDeploymentV2Props = {
    clusterName: string;
    numberOfNodes: number;
    databaseVendor: string;
    databaseVendorType: string;
    tags: string[];
    cloudProvider: string;
    cloudRegion: string;
    instanceSize: string;
    volumeType: string;
    volumeSize: number;
    volumeIops?: number;
    networkType: string;
    haEnabled: boolean;
    vpcUuid?: string;
    availabilityZones?: string[];
    version?: string;
    backupId?: string;
    dataStoreUuid?: string;
    notifications: DataStoreEmailNotificationsSettings;
};

type AddNodeProps = {
    imageId: string;
    availabilityZone: string;
    instanceSize: string;
    volumeType: string;
    volumeSize: number;
    volumeIops: number;
};

interface UpdateDeploymentProps {
    dataStoreUuid: string;
    dataStoreName?: string;
    dayOfWeek?: number;
    startHour?: number;
    endHour?: number;
    proxySqlAdminPassword?: string;
    autoscaleStorage?: boolean;
    notifications?: NotificationEmails;
}

interface NotificationEmails {
    enabled: boolean;
    emails: string[];
}

interface UpdateDatastoreStorageProps {
    dataStoreUuid: string;
    data: any;
}

interface ScaleUpdatastoreNodeProps {
    dataStoreUuid: string;
    nodeData: any[];
}

interface ScalDowndatastoreNodeProps {
    dataStoreUuid: string;
    nodeUuids: any[];
}

interface UpgradeDatastoreProps {
    dataStoreUuid: string;
    canUpgradeTo: string;
}

interface RepairNodeProps {
    dataStoreUuid: string;
    hostUuid: string;
}

export default class ProvisionService extends ApiService {
    /**
     * Updates data store settings
     * @param {...} UpdateDeploymentProps
     * @returns Promise<any>
     */
    static async updateDeployment({
        dataStoreUuid,
        dataStoreName,
        dayOfWeek,
        startHour,
        endHour,
        proxySqlAdminPassword,
        autoscaleStorage,
        notifications,
    }: UpdateDeploymentProps): Promise<any> {
        const requestData = {
            version: 2,
            data: {
                cluster_name: dataStoreName,
                maintenance_settings:
                    dayOfWeek || startHour || endHour
                        ? {
                              day_of_week: dayOfWeek,
                              start_hour: startHour,
                              end_hour: endHour,
                          }
                        : undefined,
                new_proxy_sql_password: proxySqlAdminPassword,
                spec: {
                    autoscale_storage: {
                        enabled: autoscaleStorage,
                    },
                },
                notifications,
            },
        };
        return await this.updateDeploymentSettings(dataStoreUuid, requestData);
    }

    static async updateDeploymentSettings(
        datastoreUuid: string,
        settings: any
    ) {
        return await this.request(
            'PATCH',
            `cluster/${datastoreUuid}`,
            settings
        );
    }

    /**
     * Updates datastore storage
     * @param {...} UpdateDatastoreStorageProps
     * @returns Promise<any>
     */
    static async updateDatastoreStorage({
        dataStoreUuid,
        data,
    }: UpdateDatastoreStorageProps): Promise<any> {
        const requestData = {
            version: 2,
            data,
        };
        return await this.updateStorage(dataStoreUuid, requestData);
    }

    static async updateStorage(datastoreUuid: string, storage: any) {
        return await this.request('PATCH', `cluster/${datastoreUuid}`, storage);
    }

    /**
     * ScaleUp datastore Nodes
     * @param {...} ScaleUpDatastoreNodeProps
     * @returns Promise<any>
     */
    static async scaleUpDatastoreNodes({
        dataStoreUuid,
        nodeData,
    }: ScaleUpdatastoreNodeProps): Promise<any> {
        const requestData = {
            version: 2,
            data: {
                add_nodes: {
                    specs: nodeData,
                },
            },
        };
        return await this.scaleUpNodes(dataStoreUuid, requestData);
    }

    static async scaleUpNodes(datastoreUuid: string, nodesData: any) {
        return await this.request(
            'PATCH',
            `cluster/${datastoreUuid}`,
            nodesData
        );
    }
    /**
     * Scale down Datastore nodes
     * @param {...} ScaleDownDatastoreNodeProps
     * @returns Promise<any>
     */
    static async scaleDownDatastoreNodes({
        dataStoreUuid,
        nodeUuids,
    }: ScalDowndatastoreNodeProps): Promise<any> {
        const requestData = {
            version: 2,
            data: {
                remove_nodes: {
                    node_uuids: nodeUuids,
                },
            },
        };
        return await this.scaleDownNodes(dataStoreUuid, requestData);
    }

    static async scaleDownNodes(datastoreUuid: string, nodesData: any) {
        return await this.request(
            'PATCH',
            `cluster/${datastoreUuid}`,
            nodesData
        );
    }

    /**
     * Remove a Deployment
     * @param {string} uuid
     */
    static async removeDeployment(uuid: string): Promise<any> {
        const requestData = {
            version: 2,
        };
        const response = await this.request(
            'DELETE',
            `cluster/${uuid}`,
            requestData
        );
        return response;
    }

    /**
     * Add a Deployment
     * @param {string} uuid
     */
    static async addDeployment({
        clusterName,
        clusterType,
        cloudProvider,
        region,
        dbVendor,
        instanceSize,
        deploymentSize,
        vpcUuid,
        diskSize,
        iops,
    }: AddDeploymentProps): Promise<any> {
        const requestData = {
            data: {
                cluster_name: clusterName,
                cluster_type: clusterType,
                cloud_provider: cloudProvider,
                region,
                database_vendor: dbVendor,
                instance_size: instanceSize,
                cluster_size: deploymentSize,
                vpc_uuid: vpcUuid,
                disk_size: diskSize,
                iops,
            },
        };
        return await this.request('POST', `cluster`, requestData);
    }

    /**
     * Add a Deployment
     *
     */
    static async addDeploymentV2({
        clusterName,
        numberOfNodes,
        databaseVendor,
        databaseVendorType,
        tags,
        cloudProvider,
        cloudRegion,
        instanceSize,
        volumeType,
        volumeSize,
        volumeIops,
        networkType,
        haEnabled,
        vpcUuid,
        availabilityZones,
        version,
        backupId,
        dataStoreUuid,
        notifications,
    }: AddDeploymentV2Props): Promise<any> {
        let requestData: any = {
            data: {
                general: {
                    cluster_name: clusterName,
                    cluster_size: numberOfNodes,
                    cluster_type: databaseVendorType,
                    db_vendor: databaseVendor,
                    db_version: version,
                    tags,
                },
                cloud: {
                    cloud_provider: cloudProvider,
                    cloud_region: cloudRegion,
                },
                instance: {
                    instance_size: instanceSize,
                    volume_type: volumeType,
                    volume_size: volumeSize,
                    volume_iops: volumeIops,
                },
                network: {
                    network_type: networkType,
                    ha_enabled: haEnabled,
                    vpc_uuid: vpcUuid,
                    availability_zones: availabilityZones,
                },
                notifications,
            },
            version: 2,
        };
        if (backupId) {
            requestData = {
                ...requestData,
                data: {
                    ...requestData.data,
                    from_backup: {
                        backup_id: Number(backupId),
                        datastore_uuid: dataStoreUuid,
                    },
                },
            };
        }
        return await this.request('POST', `cluster`, requestData);
    }

    /**
     * Add a Deployment node
     *
     */
    static async addNode(
        uuid: string,
        {
            imageId,
            availabilityZone,
            instanceSize,
            volumeType,
            volumeSize,
            volumeIops,
        }: AddNodeProps
    ): Promise<any> {
        const requestData = {
            data: {
                image_id: imageId,
                availability_zone: availabilityZone,
                instance_size: instanceSize,
                volume_type: volumeType,
                volume_size: volumeSize,
                volume_iops: volumeIops ? volumeIops : 0,
            },
            version: 2,
        };

        return await this.request('POST', `cluster/${uuid}/node`, requestData);
    }

    /**
     * Promote a Replica node
     *
     */
    static async promoteReplica(uuid: string, hostUuid: string): Promise<any> {
        const requestData = {
            version: 2,
            data: {
                new_master_node_id: hostUuid,
            },
        };
        return await this.request('PATCH', `cluster/${uuid}`, requestData);
    }

    /**
     * Remove a Deployment node
     *
     */
    static async removeNode(uuid: string, hostUuid: string): Promise<any> {
        const requestData = {
            version: 2,
        };
        return await this.request(
            'DELETE',
            `cluster/${uuid}/node/${hostUuid}`,
            requestData
        );
    }

    /**
     * Upgrade datastore
     * @param {...} UpgradeDatastoreProps
     * @returns Promise<any>
     */
    static async upgradeDatastore({
        dataStoreUuid,
        canUpgradeTo,
    }: UpgradeDatastoreProps): Promise<any> {
        const requestData = {
            version: 2,
            data: {
                new_server_version: canUpgradeTo,
            },
        };

        return await this.request(
            'PATCH',
            `cluster/${dataStoreUuid}`,
            requestData
        );
    }

    /**
     * Repair node
     * @param {...} RepairNodeProps
     * @returns Promise<any>
     */
    static async repairNode({
        dataStoreUuid,
        hostUuid,
    }: RepairNodeProps): Promise<any> {
        const requestData = {
            version: 2,
        };

        return await this.request(
            'POST',
            `cluster/${dataStoreUuid}/node/${hostUuid}/repair`,
            requestData
        );
    }

    protected static getApiUrl(version = 1) {
        if (version === 2) return API_PROV_V2;
        return API_PROV; // v1
    }

    static async rebootNode(dataStoreUuid: string, hostUuid: string) {
        const requestData = {
            version: 2,
        };

        const requested = await this.request(
            'POST',
            `cluster/${dataStoreUuid}/node/${hostUuid}/reboot`,
            requestData
        );
    }
}
