import { ReactElement, useEffect, useState } from 'react';
import styles from './MysqlDbDashboard.module.less';
import { Col, Row } from 'antd';
import usePrometheusMySqlQueriesStats from '../../../core/hooks/usePrometheusMySqlQueriesStats';
import PrometheusMySqlQueriesChart from '../../ccx/charts/PrometheusMySqlQueriesChart';
import PrometheusMySqlTemporaryTablesChart from '../../ccx/charts/PrometheusMySqlTemporaryTablesChart';
import PrometheusMySqlSortingChart from '../../ccx/charts/PrometheusMySqlSortingChart';
import PrometheusMySqlAbortedConnectionsChart from '../../ccx/charts/PrometheusMySqlAbortedConnectionsChart';
import usePrometheusMySqlAbortedConnectionsStats from '../../../core/hooks/usePrometheusMySqlAbortedConnectionsStats';
import usePrometheusMySqlSortingStats from '../../../core/hooks/usePrometheusMySqlSortingStats';
import usePrometheusMySqlTemporaryTablesStats from '../../../core/hooks/usePrometheusMySqlTemporaryTablesStats';
import PrometheusMySqlDbConnectionsChart from '../../ccx/charts/PrometheusMySqlDbConnectionsChart';
import usePrometheusMySqlDbConnectionsStats from '../../../core/hooks/usePrometheusMySqlDbConnectionsStats';
import Deployment from '../../../types/Deployment';

export type AllMysqlDbDashboardProps = {
    readonly uuid: string;
    readonly hostUUIDs: string[];
    readonly ports: number[];
    readonly from?: string;
    readonly to?: string;
    readonly interval: number;
    readonly hidden?: boolean;
    readonly displaySummary?: boolean;
    readonly onLoading?: Function;
    readonly deployment?: Deployment;
};

function AllMySqlDbDashboard({
    uuid,
    hostUUIDs,
    ports,
    from,
    to,
    interval,
    hidden,
    displaySummary,
    onLoading,
    deployment,
}: AllMysqlDbDashboardProps): ReactElement {
    const {
        stats: queriesStats,
        refresh: queriesStatsRefresh,
        loading: queriesStatsLoading,
    } = usePrometheusMySqlQueriesStats(uuid, '', from, to);

    const {
        stats: abortedConnectionsStats,
        refresh: abortedConnectionsStatsRefresh,
        loading: abortedConnectionsStatsLoading,
    } = usePrometheusMySqlAbortedConnectionsStats(uuid, '', from, to);

    const {
        stats: sortingStats,
        refresh: sortingStatsRefresh,
        loading: sortingStatsLoading,
    } = usePrometheusMySqlSortingStats(uuid, '', from, to);

    const {
        stats: temporaryTablesStats,
        refresh: temporaryTablesStatsRefresh,
        loading: temporaryTablesStatsLoading,
    } = usePrometheusMySqlTemporaryTablesStats(uuid, '', from, to);

    const {
        stats: connectionsStats,
        refresh: connectionsStatsRefresh,
        loading: connectionsStatsLoading,
    } = usePrometheusMySqlDbConnectionsStats(uuid, '', from, to);

    const [queriesStatsInterval, setQueriesStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [
        abortedConnectionsStatsInterval,
        setAbortedConnectionsStatsInterval,
    ] = useState<number | undefined>(undefined);
    const [connectionsStatsInterval, setConnectionsStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [sortingStatsInterval, setSortingStatsInterval] = useState<
        number | undefined
    >(undefined);
    const [temporaryTablesStatsInterval, setTemporaryTablesStatsInterval] =
        useState<number | undefined>(undefined);

    const [refreshInterval, setRefreshInterval] = useState(interval);

    useEffect(() => {
        let interval: any;
        if (queriesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                queriesStatsRefresh();
            }, refreshInterval);
            setQueriesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [queriesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (abortedConnectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                abortedConnectionsStatsRefresh();
            }, refreshInterval);
            setAbortedConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [abortedConnectionsStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (sortingStats && refreshInterval > 0) {
            interval = setInterval(() => {
                sortingStatsRefresh();
            }, refreshInterval);
            setSortingStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sortingStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (temporaryTablesStats && refreshInterval > 0) {
            interval = setInterval(() => {
                temporaryTablesStatsRefresh();
            }, refreshInterval);
            setTemporaryTablesStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [temporaryTablesStats, refreshInterval]);

    useEffect(() => {
        let interval: any;
        if (connectionsStats && refreshInterval > 0) {
            interval = setInterval(() => {
                connectionsStatsRefresh();
            }, refreshInterval);
            setConnectionsStatsInterval(interval);
        }
        return () => clearInterval(interval);
    }, [connectionsStats, refreshInterval]);

    useEffect(() => {
        if (interval === 0) {
            // when interval is 0, we must pause the refresh (clear existing intervals)
            clearInterval(queriesStatsInterval);
            clearInterval(abortedConnectionsStatsInterval);
            clearInterval(connectionsStatsInterval);
            clearInterval(sortingStatsInterval);
            clearInterval(temporaryTablesStatsInterval);
            setQueriesStatsInterval(0);
            setAbortedConnectionsStatsInterval(0);
            setConnectionsStatsInterval(0);
            setSortingStatsInterval(0);
            setTemporaryTablesStatsInterval(0);
        }
        setRefreshInterval(interval);
    }, [interval]);

    useEffect(() => {
        if (hidden) {
            // clear interval to stop updating data if the tab is not active (charts are not visible)
            clearInterval(queriesStatsInterval);
            clearInterval(abortedConnectionsStatsInterval);
            clearInterval(connectionsStatsInterval);
            clearInterval(sortingStatsInterval);
            clearInterval(temporaryTablesStatsInterval);
            setQueriesStatsInterval(0);
            setAbortedConnectionsStatsInterval(0);
            setConnectionsStatsInterval(0);
            setSortingStatsInterval(0);
            setTemporaryTablesStatsInterval(0);
        } else if (
            queriesStatsInterval === 0 ||
            abortedConnectionsStatsInterval === 0 ||
            connectionsStatsInterval === 0 ||
            sortingStatsInterval === 0 ||
            temporaryTablesStatsInterval === 0
        ) {
            // force refresh if the interval is 0, otherwise, the interval will refresh data soon
            // interval is undefined on the first load
            // interval will be 0 when paused or tab is hidden
            queriesStatsRefresh();
            abortedConnectionsStatsRefresh();
            sortingStatsRefresh();
            temporaryTablesStatsRefresh();
            connectionsStatsRefresh();
        }
    }, [hidden]);

    useEffect(() => {
        if (
            queriesStatsLoading ||
            connectionsStatsLoading ||
            abortedConnectionsStatsLoading ||
            sortingStatsLoading ||
            temporaryTablesStatsLoading
        ) {
            onLoading && onLoading(true);
        } else {
            onLoading && onLoading(false);
        }
    }, [
        queriesStatsLoading,
        abortedConnectionsStatsLoading,
        sortingStatsLoading,
        temporaryTablesStatsLoading,
        connectionsStatsLoading,
    ]);

    return (
        <section className={styles.MysqlDbDashboard}>
            <Row
                gutter={[
                    32,
                    { xs: 8, sm: 16, md: 24, lg: 32, xl: 40, xxl: 48 },
                ]}
                className={styles.MysqlDbDashboardRow}
            >
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlQueriesChart
                        data={queriesStats}
                        loading={queriesStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        deployment={deployment}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlTemporaryTablesChart
                        data={temporaryTablesStats}
                        loading={temporaryTablesStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        deployment={deployment}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                >
                    <PrometheusMySqlSortingChart
                        data={sortingStats}
                        loading={sortingStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        deployment={deployment}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                >
                    <PrometheusMySqlDbConnectionsChart
                        data={connectionsStats}
                        loading={connectionsStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        deployment={deployment}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    xxl={12}
                >
                    <PrometheusMySqlAbortedConnectionsChart
                        data={abortedConnectionsStats}
                        loading={abortedConnectionsStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        deployment={deployment}
                    />
                </Col>
                <Col
                    className={styles.MysqlDbDashboardCol}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                >
                    <PrometheusMySqlAbortedConnectionsChart
                        data={abortedConnectionsStats}
                        loading={abortedConnectionsStatsLoading}
                        displaySummary={displaySummary}
                        hostUUIDs={hostUUIDs}
                        showConnections={false}
                        deployment={deployment}
                    />
                </Col>
            </Row>
        </section>
    );
}

export default AllMySqlDbDashboard;
