import { DownloadOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import CcxContextualMenuIcon from '../ccx/icons/CcxContextualMenuIcon';

interface DataStoreLogsActionsProps {
    readonly disabled: boolean;
    readonly handleViewLogs: () => void;
    readonly downloadDataStoreLogs: () => void;
    readonly isLoading: boolean;
}

const DataStoreLogsActions = ({
    disabled,
    isLoading,
    handleViewLogs,
    downloadDataStoreLogs,
}: DataStoreLogsActionsProps) => {
    const menu = (
        <Menu>
            <Menu.Item key="viewLogs" onClick={handleViewLogs}>
                <FileTextOutlined /> View Log
            </Menu.Item>
            <Menu.Item key="downloadLogs" onClick={downloadDataStoreLogs}>
                <DownloadOutlined /> Download
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown
            overlay={menu}
            placement="bottomRight"
            trigger={['click']}
            disabled={disabled}
        >
            <Button icon={<CcxContextualMenuIcon />} loading={isLoading} />
        </Dropdown>
    );
};

export default DataStoreLogsActions;
