import React, { ReactElement, useEffect, useState } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import CmonSqlStat from '../../../types/CmonSqlStat';
import FriendlyTimestamp from '../../../types/FriendlyTimestamp';
import AppChart from '../../AppChart';
import AppChartTinyArea from '../../AppChartTinyArea';
import LazyLoader from '../../LazyLoader';
import ChartCustomTooltip from './ChartCustomTooltip';

import styles from './QueriesPerSecondChart.module.less';
import TinyChartCustomTooltip from './TinyChartCustomTooltip';

interface Props extends CcxComponentProps {
    data?: CmonSqlStat[];
    loading: boolean;
    isPostgreSql?: boolean;
    tiny?: boolean;
}

function QueriesPerSecondChart({
    data,
    isPostgreSql,
    loading,
    tiny = true,
    testId = 'QueriesPerSecondChart',
}: Props): ReactElement {
    const [chartData, setChartData] = useState<any>([]);

    useEffect(() => {
        if (data && data.length > 0) {
            const formattedData = data.map((stat: CmonSqlStat) => {
                const yValue = Math.round(
                    isPostgreSql ? stat.commits : stat.queries
                );

                return {
                    yValue,
                    xValue: new FriendlyTimestamp(stat.created).getDate(),
                };
            });

            setChartData(formattedData.flat());
        }
    }, [data]);

    const chartConfig = {
        description: {
            visible: true,
            text: '',
        },
        areaStyle: {
            fill: tiny ? '#a8c3fa' : '#530099',
        },
        height: tiny ? 75 : 100,
        width: tiny ? 200 : 500,
        autoFit: true,
        xField: 'xValue',
        yField: 'yValue',
        annotations: tiny
            ? []
            : [
                  {
                      type: 'line',
                      start: ['min', 'median'],
                      end: ['max', 'median'],
                      style: {
                          stroke: 'grey',
                          lineDash: [2, 2],
                      },
                  },
              ],
        xAxis: {
            type: 'time',
            mask: 'HH:mm',
            tickCount: 5,
        },
        yAxis: {
            label: {
                formatter: (text: string, item: any, index: number) => {
                    return text;
                },
            },
            tickCount: 3,
        },
        animation: false,
        seriesField: 'series',
        tooltip: {
            customContent: (title: any, data: any) => {
                const getTitle = () => {
                    if (!data || data.length === 0) return '';
                    return data[0]?.data?.xValue.toString();
                };

                const getContent = () => {
                    if (!data || data.length === 0) return [];
                    if (tiny) return data[0]?.data?.yValue;
                    return data.map((d: any) => ({
                        color: d?.color,
                        label: d?.data?.series,
                        value: d?.data?.yValue,
                    }));
                };

                const tooltipData = {
                    title: getTitle(),
                    content: getContent(),
                };

                return tiny ? (
                    <TinyChartCustomTooltip content={tooltipData.content} />
                ) : (
                    <ChartCustomTooltip
                        title={tooltipData.title}
                        content={tooltipData.content}
                    />
                );
            },
        },
    };

    return loading ? (
        <LazyLoader testId={testId} />
    ) : chartData.length > 0 ? (
        <div>
            <AppChart
                type="area"
                data={chartData}
                config={chartConfig}
                loading={loading}
                testId={testId}
                bordered={false}
            />
        </div>
    ) : (
        <div>
            <div className={styles.MonitorInstallationSpinner}>
                <LazyLoader testId={testId} />
            </div>
            <p className={styles.MonitorInstallationMessage}>
                Monitoring is being set up ...
            </p>
        </div>
    );
}

export default QueriesPerSecondChart;
