import { ReactElement, ReactNode } from 'react';
import CcxComponentProps from '../../../core/CcxComponent';
import styles from './ChartCustomTooltip.module.less';

export interface ChartCustomTooltipRowType {
    color?: string;
    label: string;
    value: string | number;
}

interface Props extends CcxComponentProps {
    content: ReactNode;
}

function TinyChartCustomTooltip({
    testId = 'ChartCustomTooltip',
    content,
}: Props): ReactElement {
    return (
        <div className={styles.ChartCustomTooltip} data-testid={testId}>
            <div
                className={styles.TinyChartCustomTooltipTitle}
                data-testid={`${testId}Title`}
            >
                {content}
            </div>
        </div>
    );
}

export default TinyChartCustomTooltip;
