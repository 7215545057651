import { DbLog } from './DbLog';

interface DbLogsInterface {
    files: DbLog[];
}

export default class DbLogs implements DbLogsInterface {
    readonly files: DbLog[];

    constructor(props?: DbLogsInterface) {
        this.files =
            props?.files.map((d: DbLog) => {
                return new DbLog(d);
            }) || [];
    }
}
