interface DbLogInterface {
    name: string;
}

export class DbLog implements DbLogInterface {
    readonly name: string;

    constructor(props: DbLogInterface) {
        this.name = props.name;
    }
}
