import moment from 'moment';
import { DbLogEntry } from './DbLogEntry';

interface DbLogsEntriesInterface {
    entries: DbLogEntry[];
}

export class DbLogsEntries implements DbLogsEntriesInterface {
    readonly entries: DbLogEntry[];

    constructor(props: DbLogsEntriesInterface) {
        this.entries = this.sortEntriesByTimestamp(
            props.entries.map((entry: DbLogEntry) => new DbLogEntry(entry))
        );
    }

    private sortEntriesByTimestamp(entries: DbLogEntry[]): DbLogEntry[] {
        return entries.sort(
            (a, b) =>
                moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf()
        );
    }
}
