import { ReactElement, Suspense, useEffect, useState } from 'react';
import { Badge, Tabs, TabsProps, Button, Row, Col } from 'antd';
import { useHistory, useParams } from 'react-router';
import CcxComponentProps from '../../../core/CcxComponent';
import Firewalls from '../../firewalls/Firewalls';
import LazyLoader from '../../LazyLoader';
import QueryStats from '../../queryStats/QueryStats';
import styles from './DataStore.module.less';
import DataStoreOverview from './overview/DataStoreOverview';
import ProjectBreadcrumb from '../ProjectBreadcrumb';
import DataStoreSettings from './settings/DataStoreSettings';
import DataStoreServices from './services/DataStoreServices';
import DeploymentDashboard from '../../deployments/dashboard/DeploymentDashboard';
import { hideHeader } from '../../../core/CcxEnv';
import NotFoundError404 from '../../errors/NotFoundError404';
import useThrowError from '../../errors/useThrowError';
import DbDatabases from '../../dbUsers/DbDatabase';
import DbUsers from '../../dbUsers/DbUsers';
import Backups from '../../backups/Backups';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import EventViewer from '../../eventViewer/EventViewer';
import useDataStoreJobs from '../../../core/hooks/useDataStoreJobs';
import Job from '../../../types/Job';
import usePrevious from '../../../core/hooks/usePrevious';
import {
    AlertOutlined,
    ClusterOutlined,
    ContainerOutlined,
    DatabaseOutlined,
    FileTextOutlined,
    HomeOutlined,
    LineChartOutlined,
    SafetyCertificateOutlined,
    SettingOutlined,
    SnippetsOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { getCurrentDataStore } from '../../../slices/datastore.slice';
import Datastore from '../../../types/Datastore';
import DatastoreLogs from '../../logs/DatastoreLogs';

interface UrlProps {
    activeTab: string;
    dataStoreUuid: string;
    projectUuid: string;
}

interface Props extends CcxComponentProps {}

function DataStore({ testId = 'DataStore' }: Props): ReactElement {
    const { throwError } = useThrowError();
    const history = useHistory();
    const { activeTab, dataStoreUuid, projectUuid } = useParams<UrlProps>();
    const [activeKey, setActiveKey] = useState<string>('overview');
    const [dbCount, setDbCount] = useState<number>(0);
    const [runningJobsCount, setRunningJobsCount] = useState(0);
    const dispatch = useAppDispatch();

    const { jobs } = useDataStoreJobs({
        dataStoreUuid,
    });

    const prevJobs = usePrevious(jobs);

    // Updating the datastores if any changes to the job state happens
    useEffect(() => {
        if (
            JSON.stringify(prevJobs) !== JSON.stringify(jobs) ||
            dataStoreUuid
        ) {
            dispatch(getCurrentDataStore({ dataStoreUuid }));
        }
    }, [dataStoreUuid, jobs]);

    useEffect(() => {
        if (jobs && !!jobs.length) {
            const runningJobsCount = jobs.filter((job: Job) =>
                job.isStatusRunning()
            ).length;

            setRunningJobsCount(runningJobsCount);
        }
    }, [jobs, setRunningJobsCount]);
    const {
        dataStore: deployment,
        isLoading,
        isError,
    } = useAppSelector((state) => state.dataStore);

    const switchTab = (tab: string) => {
        history.push(
            `/projects/${projectUuid}/data-stores/${dataStoreUuid}/${tab}`
        );
    };

    useEffect(() => {
        if (isError) {
            throwError(new NotFoundError404('Datastore not found'));
        }
    }, [isError]);

    useEffect(() => {
        setActiveKey(activeTab);
    }, [activeTab]);

    const cancel = () => {
        history.push({
            pathname: '/',
        });
    };

    const items: TabsProps['items'] = [
        {
            key: 'overview',
            label: (
                <div className={styles.TabsStyle}>
                    <HomeOutlined className={styles.IconStyle} /> Overview
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreOverview dataStore={deployment} />
                </Suspense>
            ),
        },
        {
            key: 'services',
            label: (
                <div className={styles.TabsStyle}>
                    <ClusterOutlined className={styles.IconStyle} />
                    Nodes
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreServices
                        switchTab={switchTab}
                        dataStore={deployment}
                        setDbCount={setDbCount}
                    />
                </Suspense>
            ),
        },
        deployment && {
            key: 'monitoring',
            label: (
                <div className={styles.TabsStyle}>
                    <LineChartOutlined className={styles.IconStyle} />{' '}
                    Monitoring
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DeploymentDashboard currentDeployment={deployment} />
                </Suspense>
            ),
        },
        deployment && {
            key: 'eventViewer',
            label: (
                <div className={styles.TabsStyle}>
                    {' '}
                    <AlertOutlined className={styles.IconStyle} />
                    Events & Logs
                    {runningJobsCount > 0 ? (
                        <Badge
                            count={runningJobsCount}
                            className={styles.TabsBadge}
                        />
                    ) : (
                        ''
                    )}
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <EventViewer currentDeployment={deployment} />
                </Suspense>
            ),
        },
        deployment &&
            !deployment.isRedis() && {
                key: 'queryStats',
                label: (
                    <div className={styles.TabsStyle}>
                        <SnippetsOutlined className={styles.IconStyle} />
                        Query stats
                    </div>
                ),
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <QueryStats currentDeployment={deployment} />
                    </Suspense>
                ),
            },
        deployment && {
            key: 'dbUsers',
            label: (
                <div className={styles.TabsStyle}>
                    <UserOutlined className={styles.IconStyle} /> Users
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DbUsers currentDeployment={deployment} />
                </Suspense>
            ),
        },
        deployment &&
            !deployment.isRedis() && {
                key: 'dbDatabases',
                label: (
                    <div className={styles.TabsStyle}>
                        <DatabaseOutlined className={styles.IconStyle} />{' '}
                        Databases
                    </div>
                ),
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <DbDatabases currentDeployment={deployment} />
                    </Suspense>
                ),
            },
        {
            key: 'backups',
            label: (
                <div className={styles.TabsStyle}>
                    <ContainerOutlined className={styles.IconStyle} /> Backups
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <Backups />
                </Suspense>
            ),
        },
        deployment &&
            !deployment.isVMWare() && {
                key: 'firewall',
                label: (
                    <div className={styles.TabsStyle}>
                        <SafetyCertificateOutlined
                            className={styles.IconStyle}
                        />{' '}
                        Firewall
                    </div>
                ),
                children: (
                    <Suspense fallback={<LazyLoader />}>
                        <Firewalls currentDeployment={deployment} />
                    </Suspense>
                ),
            },
        {
            key: 'settings',
            label: (
                <div className={styles.TabsStyle}>
                    <SettingOutlined className={styles.IconStyle} />
                    Settings
                    {deployment?.can_upgrade_to ? (
                        <Badge count={1} className={styles.TabsBadge} />
                    ) : (
                        ''
                    )}
                </div>
            ),
            children: (
                <Suspense fallback={<LazyLoader />}>
                    <DataStoreSettings currentDeployment={deployment} />
                </Suspense>
            ),
        },
    ].filter((item) => !!item) as TabsProps['items'];
    if (isLoading && !deployment) return <></>;
    return (
        <section className={styles.DataStore} data-testid={testId}>
            <ProjectBreadcrumb
                deployment={deployment}
                testId={`${testId}Breadcrumb`}
            />

            <Tabs
                className={styles.DataStoreTabs}
                data-testid={`${testId}Tabs`}
                type="card"
                activeKey={activeKey}
                defaultActiveKey={activeTab}
                destroyInactiveTabPane={true}
                onChange={(tabKey: any) => {
                    history.push(
                        `/projects/${projectUuid}/data-stores/${dataStoreUuid}/${tabKey}`
                    );
                }}
                items={items}
            />

            {hideHeader && (
                <Row className={styles.DataStoreBackRow}>
                    <Col span={2} offset={22}>
                        <Button onClick={() => cancel()}>Back</Button>
                    </Col>
                </Row>
            )}
        </section>
    );
}

export default DataStore;
